<template>
  <b-modal
    id="modal-link-invoice"
    ref="refModalLinkInvoice"
    :title="t('Liên kết hóa đơn')"
    size="lg"
    :ok-title="isSubmitting ? t('Đang lưu') : t('Lưu')"
    :ok-disabled="isSubmitting"
    :cancel-title="t('Huỷ')"
    cancel-variant="outline-secondary"
    :hide-header-close="true"
    no-close-on-backdrop
    @show="resetModal"
    @hidden="resetModal"
    @ok="onSubmit"
  >

    <b-alert
      v-if="item"
      show
      variant="danger"
    >
      <div class="alert-body">
        <feather-icon
          class="mr-25"
          icon="StarIcon"
        />
        <span class="ml-25">{{ t('Vui lòng chọn hóa đơn để liên kết cho khoản thu có nội dung chuyển khoản là') }} {{ itemLocal.tingeeTransaction ? `"${itemLocal.tingeeTransaction.content}"` : '' }} {{ t(' với số tiền là') }} {{ Number(itemLocal.amount).toLocaleString() }} đ.</span>
      </div>
    </b-alert>
    <!-- Body -->
    <validation-observer
      #default="{ handleSubmit }"
      ref="refFormObserver"
    >
      <b-form
        @submit.prevent="handleSubmit(onSubmit)"
        @reset.prevent="resetForm"
      >

        <b-row>

          <b-col md="6">
            <validation-provider
              #default="validationContext"
              name="school"
              rules="required"
            >
              <select-school
                v-model="itemLocal.school"
                :filter-active="true"
                required
                :default-value="itemLocal.school"
                label="Cơ sở"
                :state="getValidationState(validationContext)"
                :error="validationContext.errors[0]"
              />
            </validation-provider>
          </b-col>
          <b-col md="6">
            <validation-provider
              #default="validationContext"
              name="class"
              rules="required"
            >
              <select-class
                v-model="itemLocal.class"
                :filter-active="true"
                required
                :school="itemLocal.school"
                :default-value="itemLocal.class"
                label="Lớp học"
                :state="getValidationState(validationContext)"
                :error="validationContext.errors[0]"
              />
            </validation-provider>
          </b-col>
          <b-col md="6">
            <validation-provider
              #default="validationContext"
              name="student"
              rules="required"
            >
              <select-student
                v-model="itemLocal.student"
                :filter-active="true"
                required
                :class-object="itemLocal.class"
                :default-value="itemLocal.student"
                label="Học sinh"
                :state="getValidationState(validationContext)"
                :error="validationContext.errors[0]"
              />
            </validation-provider>
          </b-col>
          <b-col md="6">
            <validation-provider
              #default="validationContext"
              name="invoice"
              rules="required"
            >
              <select-invoice
                v-model="itemLocal.invoice"
                :enable="itemLocal.student && itemLocal.student.id > 0"
                :school="itemLocal.school"
                :student="itemLocal.student"
                :default-value="itemLocal.invoice"
                label="Hóa đơn"
                :state="getValidationState(validationContext)"
                :error="validationContext.errors[0]"
              />
            </validation-provider>
          </b-col>

        </b-row>

      </b-form>
    </validation-observer>
  </b-modal>
</template>

<script>
import {
  BModal,
  BForm,
  BRow,
  BCol,
  BAlert,
} from 'bootstrap-vue';
import { ValidationObserver, ValidationProvider } from 'vee-validate';
import { required, maxValue } from '@validations';
import formValidation from '@core/comp-functions/forms/form-validation';
import SelectSchool from '@/views/components/SelectSchool.vue';
import SelectClass from '@/views/components/SelectClass.vue';
import SelectStudent from '@/views/components/SelectStudent.vue';
import SelectInvoice from '@/views/components/SelectInvoice.vue';
import useLinkInvoiceModal from "./useLinkInvoiceModal";

export default {
  components: {
    BModal,
    BRow,
    BCol,
    BForm,
    ValidationObserver,
    ValidationProvider,
    BAlert,

    SelectSchool,
    SelectClass,
    SelectStudent,
    SelectInvoice,
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      required,
      maxValue,
    };
  },
  setup(props, { emit }) {
    const {
      refFormObserver, getValidationState, resetForm, clearForm,
    } = formValidation();

    const {
      refModalLinkInvoice,
      itemLocal,
      isSubmitting,
      resetItemLocal,
      resetModal,
      t,
      onSubmit,
      resolveColWidthIfDisableBed,
    } = useLinkInvoiceModal(props, emit, refFormObserver);

    return {
      refModalLinkInvoice,
      itemLocal,
      isSubmitting,
      resetItemLocal,
      resetModal,
      onSubmit,
      t,
      // Form Validation
      resetForm,
      clearForm,
      refFormObserver,
      getValidationState,
      resolveColWidthIfDisableBed,
    };
  },
};
</script>
