import {
  ref, watch, onUnmounted, getCurrentInstance,
} from '@vue/composition-api';
import store from '@/store';
import moment from 'moment';
import toast from '@/utils/toast';
import { isEnableBed } from '@/auth/utils';
import incomeExpenseStoreModule from '../incomeExpenseStoreModule';

export default function useIncomeExpenseList() {
  const STORE_MODULE_NAME = 'incomeExpense';

  // Register module
  if (!store.hasModule(STORE_MODULE_NAME)) {
    store.registerModule(STORE_MODULE_NAME, incomeExpenseStoreModule);
  }

  // UnRegister on leave
  onUnmounted(() => {
    if (store.hasModule(STORE_MODULE_NAME)) {
      store.unregisterModule(STORE_MODULE_NAME);
    }
  });
  // Use toast
  const toastification = toast();

  const blankItem = {
    id: 0,
    isIncome: true,
    type: null,
    name: '',
    issueDate: moment(new Date()).toDate(),
    amount: null,
    cashbook: null,
    contract: null,
    reservation: null,
    payer: '',
    receiver: '',
    note: '',
    allocation: true,
    allocationStartDate: null,
    allocationEndDate: null,
    items: [],
    // attachment
    attachments: [],
    // repeat
    repeatCycle: 0,
    repeatCycleObject: null,
    repeatInfinity: true,
    repeatCount: 0,
  };

  const item = ref(JSON.parse(JSON.stringify(blankItem)));
  const resetItem = () => {
    item.value = JSON.parse(JSON.stringify(blankItem));
  };

  const vm = getCurrentInstance().proxy;
  const isHidden = !vm.$can('delete', 'income-expense');

  // Table Handlers
  const columns = [
    {
      label: 'Mã',
      field: 'code',
      width: '80px',
    },
    {
      label: 'Thao tác',
      field: 'action',
      tdClass: 'text-center',
      sortable: false,
      width: '90px',
      hidden: isHidden,
    },
    {
      label: 'Tên',
      field: 'name',
    },
    {
      label: 'Số tiền thu',
      field: 'amount',
      type: 'number',
    },
    {
      label: 'Thời gian',
      field: 'issueDate',
    },
    {
      label: 'Người trả',
      field: 'payer',
    },
    {
      label: 'Tài khoản thu',
      field: 'tingee',
    },

  ];
  const rows = ref([]);
  const analytic = ref(null);
  const school = ref(null);
  const classObject = ref(null);
  const student = ref(null);
  // filter
  const tingee = ref(null);
  const startDate = ref(null);
  const endDate = ref(null);
  // current selected rows
  const selectedRows = ref([]);
  const searchTerm = ref('');
  // ssr
  const isLoading = ref(false);
  const totalRecords = ref(0);
  const serverParams = ref({
    searchTerm: '',
    filter: {},
    sort: {},
    page: 1,
    perPage: 20,
  });

  //   API Call
  const fetchIncomeExpenses = () => {
    isLoading.value = false;
    store
      .dispatch('incomeExpense/fetchIncomeExpenses', serverParams.value)
      .then(response => {
        const { total, items } = response.data;
        totalRecords.value = total;
        rows.value = items;
      })
      .catch(error => {
        toastification.showToastError(error);
      });
  };

  const fetchAnalytics = () => {
    isLoading.value = false;
    store
      .dispatch('incomeExpense/fetchAnalytics', serverParams.value)
      .then(response => {
        analytic.value = response.data;
      })
      .catch(error => {
        toastification.showToastError(error);
      });
  };

  const exportData = () => {
    store
      .dispatch('incomeExpense/exportIncomeExpenses', serverParams.value)
      .then(response => {
        window.location.href = response.data;
      })
      .catch(error => {
        toastification.showToastError(error);
      });
  };

  const fetchData = () => {
    fetchAnalytics();
    fetchIncomeExpenses();
  };

  const downloadImportTemplate = () => {
    store
      .dispatch('incomeExpense/generateImportIncomeExpenses', serverParams.value)
      .then(response => {
        window.location.href = response.data;
      })
      .catch(error => {
        toastification.showToastError(error);
      });
  };

  const importData = data => {
    const formData = new FormData();
    formData.append('file', data.file);
    store
      .dispatch('incomeExpense/importIncomeExpenses', formData)
      .then(() => {
        fetchData();
        toastification.showToastCreateSuccess();
        vm.$router
          .push({ name: 'jobs' });
      })
      .catch(error => {
        toastification.showToastError(error);
      });
  };

  const deleteIncomeExpenses = incomeExpenses => {
    store
      .dispatch('incomeExpense/deleteIncomeExpenses', {
        ids: incomeExpenses.map(_obj => _obj.id),
      })
      .then(() => {
        fetchData();
        toastification.showToastDeleteSuccess();
      })
      .catch(error => {
        toastification.showToastError(error);
      });
  };

  const printIncomeExpense = incomeExpense => {
    store
      .dispatch('incomeExpense/getIncomeExpenseHtml', incomeExpense.id)
      .then(response => {
        const w = window.open();
        w.document.write(response.data);
        w.document.close();
        w.setTimeout(() => {
          w.print();
        }, 1000);
      })
      .catch(error => {
        toastification.showToastError(error);
      });
  };

  const approveIncomeExpense = (incomeExpenses, approve) => {
    if (incomeExpenses && incomeExpenses.length > 0) {
      store.dispatch('incomeExpense/approveIncomeExpenses', { approve, ids: incomeExpenses.map(_obj => _obj.id) }).then(() => {
        fetchData();
      }).catch(error => {
        toastification.showToastError(error);
      });
    }
  };

  //   Methods
  const updateParams = newProps => {
    serverParams.value = { ...serverParams.value, ...newProps };
    fetchData();
  };

  const onPageChange = params => {
    updateParams({ page: params.currentPage });
  };

  const onPerPageChange = params => {
    updateParams({ perPage: params.currentPerPage });
  };

  const onSortChange = params => {
    updateParams({
      sort: {
        type: params[0].type,
        field: params[0].field,
      },
    });
  };

  const onColumnFilter = params => {
    updateParams(params);
  };

  const selectionChanged = params => {
    selectedRows.value = params.selectedRows;
  };

  const onEditItem = val => {
    item.value = val;
  };

  //   Watch
  watch(school, val => {
    const { filter } = serverParams.value;
    if (val) {
      filter.schoolId = val.id;
    } else {
      delete filter.schoolId;
    }
    updateParams({ filter });
  });
  watch(classObject, val => {
    const { filter } = serverParams.value;
    if (val) {
      filter.classId = val.id;
    } else {
      delete filter.classId;
    }
    updateParams({ filter });
  });
  watch(student, val => {
    const { filter } = serverParams.value;
    if (val) {
      filter.studentId = val.id;
    } else {
      delete filter.studentId;
    }
    updateParams({ filter });
  });
  watch(tingee, val => {
    const { filter } = serverParams.value;
    if (val) {
      filter.tingeeId = val.id;
    } else {
      delete filter.tingeeId;
    }
    updateParams({ filter });
  });

  watch(startDate, val => {
    const { filter } = serverParams.value;
    if (moment(val, 'DD-MM-YYYY').isValid()) {
      filter.startDate = val;
    } else {
      delete filter.startDate;
    }
    updateParams({ filter });
  });
  watch(endDate, val => {
    const { filter } = serverParams.value;
    if (moment(val, 'DD-MM-YYYY').isValid()) {
      filter.endDate = val;
    } else {
      delete filter.endDate;
    }
    updateParams({ filter });
  });

  watch(searchTerm, val => {
    updateParams({ searchTerm: val });
  });

  const setApproveHidden = incomeExpenses => {
    // Tat ca hoa don dang o trang thai chua duyet thi se hien thi nut approve

    const numOfApprove = incomeExpenses.filter(obj => obj.approve === false).length;
    return !(incomeExpenses.length > 0 && numOfApprove === incomeExpenses.length);
  };

  const setDeclineHidden = incomeExpenses => {
    // Hiển thị khi tất cả hóa đơn ở trạng thái đã duyệt
    const numOfDecline = incomeExpenses.filter(obj => obj.approve === true).length;
    return !(incomeExpenses.length > 0 && numOfDecline === incomeExpenses.length);
  };

  const resolveColWidthIfDisableBed = isBed => {
    if (isEnableBed()) {
      return 2;
    }
    if (isBed) {
      return 0;
    }
    return 3;
  };

  return {
    analytic,
    item,
    columns,
    rows,
    tingee,
    school,
    classObject,
    student,
    startDate,
    endDate,
    selectedRows,
    searchTerm,
    isLoading,
    totalRecords,
    serverParams,
    fetchData,
    fetchIncomeExpenses,
    deleteIncomeExpenses,
    onEditItem,
    resetItem,
    updateParams,
    onPageChange,
    onPerPageChange,
    onSortChange,
    onColumnFilter,
    selectionChanged,
    printIncomeExpense,
    exportData,
    importData,
    downloadImportTemplate,
    setApproveHidden,
    setDeclineHidden,
    approveIncomeExpense,
    resolveColWidthIfDisableBed,
  };
}
