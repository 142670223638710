import useJwt from '@/auth/jwt/useJwt';

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchIncomeExpenses(ctx, params) {
      return useJwt.getIncomeExpenses(params).then(response => response);
    },
    fetchAnalytics(ctx, params) {
      return useJwt.getIncomeExpenseAnalytics(params).then(response => response);
    },
    exportIncomeExpenses(ctx, params) {
      return useJwt.exportIncomeExpenses(params).then(response => response);
    },
    getIncomeExpenseHtml(ctx, id) {
      return useJwt.getIncomeExpenseHTML(id).then(response => response);
    },
    getIncomeExpenseDetail(ctx, id) {
      return useJwt.getIncomeExpenseDetail(id).then(response => response);
    },
    createIncomeExpense(ctx, data) {
      return useJwt.createIncomeExpense(data).then(response => response);
    },
    updateIncomeExpense(ctx, data) {
      return useJwt.updateIncomeExpense(data).then(response => response);
    },
    calculateAllocation(ctx, data) {
      return useJwt.calculateAllocation(data).then(response => response);
    },
    deleteIncomeExpenses(ctx, data) {
      return useJwt.deleteIncomeExpenses(data).then(response => response);
    },
    importIncomeExpenses(ctx, data) {
      return useJwt.importIncomeExpense(data).then(response => response);
    },
    generateImportIncomeExpenses(ctx, data) {
      return useJwt.generateImportIncomeExpensesTemplate(data).then(response => response);
    },
    approveIncomeExpenses(ctx, data) {
      return useJwt.approveIncomeExpenses(data).then(response => response);
    },
    linkInvoice(ctx, data) {
      return useJwt.linkInvoice(data).then(response => response);
    },
  },
};
